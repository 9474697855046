import styles from './EducationCertificationsItem.module.css';

const files = require.context('../../../../../files', true);
const images = require.context('../../../../../images', true);

function EducationCertificationsItem({ item }) {
    return (
        <div className={`${styles['education-certification-item']}`}>
            <span id={item.anchor} className={styles['education-certification-item-anchor']}></span>
            <div className={`${styles['education-certification-item-logo']}`}>
                <img
                    src={images(`./${item.image}`)}
                    alt={item.image}
                    className={`${styles['education-certification-item-image']}`}
                />
            </div>

            <div className={`${styles['education-certification-item-details']}`}>
                <h1>
                    <a
                        href={item.infoLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={`${styles['education-certification-item-name']}`}
                    >
                        {item.name}
                    </a>
                </h1>
                <h2 className={`${styles['education-certification-item-organization']}`}>
                    {item.organization}
                </h2>
                <h3 className={`${styles['education-certification-item-duration']}`}>
                    {item.duration}
                </h3>
                <a
                    href={files(`./${item.certificateLink}`)}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`${styles['education-certification-item-certificate']}`}
                >
                    View Certificate
                </a>
            </div>
        </div>
    );
}

export default EducationCertificationsItem;
