import styles from './HomeContacts.module.css';
import data from '../../../data/Data';
import { generalSvg } from 'com-ashatou-ui/dist';

function HomeContacts() {
    let contacts = data.contacts;

    return (
        <ul className={styles['home-contacts']}>
            {contacts.map((contact) => {
                return (
                    <li key={contact.key} className={styles['home-contacts-item']}>
                        <a
                            href={contact.link}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={styles['home-contacts-link']}
                        >
                            <img
                                src={generalSvg(`./${contact.icon}`)}
                                alt={contact.icon}
                                className={styles['home-contacts-icon']}
                            />
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}

export default HomeContacts;
