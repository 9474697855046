const Data = {
    workExperiences: {
        brief: [
            {
                key: '1',
                icon: 'year.svg',
                number: '15+',
                unit: 'Years',
                experience: 'in software engineering',
                alt: 'years svg',
            },
            {
                key: '2',
                icon: 'project.svg',
                number: '12',
                unit: 'Projects',
                experience: 'cloud mobile solutions',
                alt: 'project svg',
            },
            {
                key: '3',
                icon: 'organization.svg',
                number: '10+',
                unit: 'Organizations',
                experience: 'and start-ups trusted me',
                alt: 'organization svg',
            },
        ],
        list: [
            {
                key: '1',
                anchor: 'aqar-solutions-1',
                company: 'Aqar Solutions',
                position: 'Engineering Director',
                duration: 'May 2021 - January 2023 (1 year, 8 months)',
                logo: 'work-experience/aqarmap-logo.png',
                website: 'https://www.aqarmap.com.eg',
                year: '2021',
                responsibilities:
                    'I managed the engineering team and process. Along with the Engineering managers, we built the performance system and the career progression system. I supervised the total quality management process including the software quality assurance and emergency handling. I helped maintaining the cloud infrastructure. I worked with the team on delivery requirements and built a system to sustain software development within the organization.',
            },
            {
                key: '2',
                anchor: 'areeb-technology',
                company: 'Areeb Technology',
                position: 'Product Manager',
                duration: 'November 2020 - May 2021 (6 months)',
                logo: 'work-experience/areeb-logo.jpg',
                website: 'https://www.areebtechnology.com',
                year: '2020',
                responsibilities:
                    "I provided minor product management support for Areeb's mobile applications. I worked on supporting the development and testing operations as well.",
            },
            {
                key: '3',
                anchor: 'itworx',
                company: 'ITWorx',
                position: 'Principal Software Engineer',
                duration: 'November 2017 - October 2020 (3 years)',
                logo: 'work-experience/itworx-logo.png',
                website: 'https://www.itworx.com',
                year: '2017',
                responsibilities:
                    'I was the mobile practice mentor and coach. I supported all mobile developers in the organization in form of individual development plan preparation, follow-up, and appraisal. During this period, I developed a new performance measurement system for all talents of the practice. I provided engineering support in form of Code Inspection and requirements estimates.',
            },
            {
                key: '4',
                anchor: 'aqar-solutions-2',
                company: 'Aqar Solutions',
                position: 'Scrum Master/Agile Tester',
                duration: 'August 2016 - October 2017 (1 year, 2 months)',
                logo: 'work-experience/aqarmap-logo.png',
                website: 'https://www.aqarmap.com.eg',
                year: '2016',
                responsibilities:
                    "I helped setup Scrum for the mobile development team. We follow Scrum as defined in the guide. After that, I worked as an Agile Tester helping delivering quality applications. I lead the mobile team during the launch of a new mobile app on both iOS and Android while support Aqarmap's main application.",
            },
            {
                key: '5',
                anchor: 'youxel',
                company: 'Youxel',
                position: 'Mobile Development/Design Consultant',
                duration: 'October 2014 - July 2015 (9 months)',
                logo: 'work-experience/youxel-logo.jpg',
                website: 'https://www.youxel.com',
                year: '2015',
                responsibilities:
                    'I provided development and design consultation service for the mobile team.',
            },
            {
                key: '6',
                anchor: 'medsconnect',
                company: 'MedsConnect',
                position: 'Product Designer/Manager',
                duration: 'July 2014 - December 2016 (2 years, 6 months)',
                logo: 'work-experience/medsconnect-logo.png',
                website: '',
                year: '2014',
                responsibilities:
                    'During my work at this amazing startup, I took many roles. I worked as iOS and Android developer. I designed the GFX and the UI. I wrote my code using Test-driven Development (TDD). I helped setup JIRA and Continuos Delivery system.',
            },
            {
                key: '7',
                anchor: 'pentavalue',
                company: 'PentaValue',
                position: 'Mobile Development Manager',
                duration: 'April 2014 - August 2014 (4 months)',
                logo: 'work-experience/pentavalue-logo.jpg',
                website: 'https://pentavalue.com/en',
                year: '2014',
                responsibilities:
                    'I provided technical support for all mobile development operations.',
            },
            {
                key: '8',
                anchor: 'freelancer',
                company: 'Freelancer',
                position: 'Freelance iOS/Android Application Developer',
                duration: 'December 2013 - December 2014 (4 months)',
                logo: 'work-experience/freelancer-logo.jpg',
                website: '',
                year: '2013',
                responsibilities:
                    'I worked as a contractor for several small iOS and Android projects. I wrote content for iOS basic and advanced courses as well.',
            },
            {
                key: '9',
                anchor: 'ibm',
                company: 'IBM',
                position: 'Application Developer',
                duration: 'December 2012 - November 2013 (1 year)',
                logo: 'work-experience/ibm-logo.png',
                website: 'https://www.ibm.com/us-en',
                year: '2012',
                responsibilities:
                    'I worked on the iOS app "Shell Retail Site Manager" developed for Shell. We started this app with different features including dynamic localization, offline support, iOS 7 support, and iPad support in 2 successive versions. During this period, I studied Android development and started developing for Android as well.',
            },
            {
                key: '10',
                anchor: 'asgatech',
                company: 'Asgatech',
                position: 'Senior iOS Developer',
                duration: 'July 2012 - November 2012 (4 months)',
                logo: 'work-experience/asgatech-logo.png',
                website: 'https://asgatech.com',
                year: '2012',
                responsibilities:
                    'I worked on iOS application development for the "General Authority of Islamic Affairs & Endowments" in UAE. The application supported many features and was considered a big hit back then. This was my first job after a year and half as an independent developer learning programming and User Experience design.',
            },
            {
                key: '11',
                anchor: 'enppi',
                company: 'Enppi',
                position: 'Electrical Engineer',
                duration: 'April 2007 - April 2011 (4 years)',
                logo: 'work-experience/enppi-logo-light.png',
                website: 'https://www.enppi.com',
                year: '2007',
                responsibilities:
                    'I worked as an Electrical Engineer participating in electrical engineering projects of Oil & Gas facilities. Clients I served includes Aramco (Saudi Arabia), PDVSA (Venezuela), and GABCO (Egypt). My work included Power System Analysis, cabling, lighting, switch gears and MCC, MRQ, MRP, inspection, and construction supervision. I switched career to software engineering after serving for 4 years.',
            },
            {
                key: '12',
                anchor: 'fue',
                company: 'Future University in Egypt',
                position: 'Teaching Assistant',
                duration: 'September 2006 - March 2007 (8 months)',
                logo: 'work-experience/fue-logo.jpg',
                website: 'https://www.fue.edu.eg/',
                year: '2006',
                responsibilities:
                    'I worked as a teaching assistance for one semester. I participated in Mathematics, Physics, Mechanics, and Intro to Computers tutorials.',
            },
        ],
    },

    testimonials: [
        {
            key: '1',
            anchor: 'ibm',
            quote: 'Professional, knowledgeable, and result oriented with theoretical and practical understanding of work requirements.',
            name: 'Kareem Morsy',
            position: 'People Manager',
            company: 'IBM, 2013',
            photo: 'testimonials/kareem-morsy.jpeg',
            alt: 'Photo of Kareem Morsy, People Manager, IBM, 2013',
            letter: 'testimonials/letter-ibm.jpg',
            referenceLink: 'https://www.linkedin.com/in/kareem-morsy-77146716/',
        },
        {
            key: '2',
            anchor: 'youxel',
            quote: 'We have observed him as professional and provided his work efficiently and precisely.',
            name: 'Mohamed ElGamal',
            position: 'CEO',
            company: 'Youxel, 2014',
            photo: 'testimonials/mohamed-elgamal.jpeg',
            alt: 'Photo of Mohamed ElGamal, CEO, Youxel, 2014',
            letter: 'testimonials/letter-youxel.jpg',
            referenceLink: 'https://eg.linkedin.com/in/melgamal',
        },
        {
            key: '3',
            anchor: 'medsconnect',
            quote: 'Professionally delivering, greatly supporting, extremely bright and engaging, we always count on him. He gets the job done perfect the first time. He is efficient in planning his work, punctual in meeting deadlines and consistently exceeds our expectations and requirements.',
            name: 'Amira Badran',
            position: 'Co­‐Founder/ Managing Partner',
            company: 'MedsConnect FZE, 2015',
            photo: 'testimonials/amira-awwad.jpeg',
            alt: 'Photo of Amira Awwad, Co­‐Founder/ Managing Partner, MedsConnect FZE, 2015',
            letter: 'testimonials/letter-medsconnect.pdf',
            referenceLink: 'https://www.linkedin.com/in/amiraawwad/',
        },
        {
            key: '4',
            anchor: 'aqarmap',
            quote: 'I can genuinely say that very few people joined our company and caused a positive dent in its process, culture, quality standards, and products. If you are looking for a product-oriented team member who will not sacrifice quality while being very productive [at] the same time, Mr. Abdalrahman is the exact person you are looking for.',
            name: 'Amad Almsaodi',
            position: 'CEO',
            company: 'Aqarmap, 2017',
            photo: 'testimonials/amad-almsaodi.jpeg',
            alt: 'Photo of Amad Almsaodi, CEO, Aqarmap, 2017',
            letter: 'testimonials/letter-aqarmap.pdf',
            referenceLink: 'https://www.linkedin.com/in/almsaodi',
        },
        {
            key: '5',
            anchor: 'asgatech',
            quote: 'We have observed him as professional and discharged his work of his position very efficiently and conscientiously. His character and conduct during his [work] period has been exemplary.',
            name: 'Maged Shaker',
            position: 'CEO',
            company: 'asgatech, 2012',
            photo: 'testimonials/maged-shaker.jpeg',
            alt: 'Photo of Maged Shaker, CEO, asgatech, 2012',
            letter: 'testimonials/letter-asgatech.jpg',
            referenceLink: 'https://www.linkedin.com/in/maged-shaker-24524012',
        },
        {
            key: '6',
            anchor: 'areeb',
            quote: 'His honesty and dedication to assignments prove that he is an asset for any organization.',
            name: 'Maged Gebriel',
            position: 'CEO',
            company: 'Areeb Group, 2021',
            photo: 'testimonials/maged-gebriel.jpeg',
            alt: 'Photo of Maged Gebriel, CEO, Areeb Group, 2021',
            letter: 'testimonials/letter-areeb.pdf',
            referenceLink: 'https://www.linkedin.com/in/magedgebriel/',
        },
        {
            key: '7',
            anchor: 'itworx',
            quote: 'I can genuinely say that very few people joined our company and caused a positive dent in its process, culture, quality standards, and products. If you are looking for a product-oriented team member who will not sacrifice quality while being very productive [at] the same time, Mr. Abdalrahman is the exact person you are looking for.',
            name: 'Ayman Roshdy',
            position: 'Services Capability Director',
            company: 'ITWorx, 2020',
            photo: 'testimonials/ayman-roshdy.jpeg',
            alt: 'Photo of Ayman Roshdy, Services Capability Director, ITWorx, 2020',
            letter: 'testimonials/letter-ibm.jpg',
            referenceLink: 'https://www.linkedin.com/in/ayman-roshdy-7790306',
        },
    ],

    certifications: {
        latest: {
            anchor: 'aws-dop',
            name: 'AWS Certified DevOps Engineer - Professional (DOP)',
            organization: 'Amazon Web Services (AWS)',
            duration: 'September 2024 - September 2027',
            infoLink:
                'https://aws.amazon.com/certification/certified-devops-engineer-professional/',
            certificateLink: 'education/aws-dop.pdf',
            image: 'education/aws-dop.png',
        },
        list: [
            {
                key: '1',
                title: 'Software Engineering & Architecture',
                icon: 'code.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'aws-saa',
                        name: 'AWS Certified Solutions Architect - Associate (SAA)',
                        organization: 'Amazon Web Services (AWS)',
                        duration: 'September 2024 - September 2027',
                        infoLink:
                            'https://aws.amazon.com/certification/certified-solutions-architect-associate/',
                        certificateLink: 'education/aws-saa.pdf',
                        image: 'education/aws-saa.png',
                    },
                    {
                        key: '2',
                        anchor: 'aws-dva',
                        name: 'AWS Certified Developer - Associate (DVA)',
                        organization: 'Amazon Web Services (AWS)',
                        duration: 'December 2021 - December 2024',
                        infoLink:
                            'https://aws.amazon.com/certification/certified-developer-associate/',
                        certificateLink: 'education/aws-dva.pdf',
                        image: 'education/aws-dva.png',
                    },
                    {
                        key: '3',
                        anchor: 'acsd',
                        name: 'Advanced Certified Scrum Developer (A-CSD)',
                        organization: 'Scrum Alliance',
                        duration: 'August 2019 - May 2022',
                        infoLink:
                            'https://www.scrumalliance.org/get-certified/developer-track/advanced-certified-scrum-developer',
                        certificateLink: 'education/acsd.pdf',
                        image: 'education/seal-advcsd.png',
                    },
                ],
            },
            {
                key: '2',
                title: 'Cloud & DevOps Engineering',
                icon: 'cloud.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'ckad',
                        name: 'Certified Kubernetes Application Developer (CKAD)',
                        organization: 'The Linux Foundation',
                        duration: 'August 2022 - August 2025',
                        infoLink: 'https://www.cncf.io/certification/ckad/',
                        certificateLink: 'education/ckad.pdf',
                        image: 'education/lf-ckad.png',
                    },
                    {
                        key: '2',
                        anchor: 'aws-dop',
                        name: 'AWS Certified DevOps Engineer - Professional (DOP)',
                        organization: 'Amazon Web Services (AWS)',
                        duration: 'September 2024 - September 2027',
                        infoLink:
                            'https://aws.amazon.com/certification/certified-devops-engineer-professional/',
                        certificateLink: 'education/aws-dop.pdf',
                        image: 'education/aws-dop.png',
                    },
                    {
                        key: '3',
                        anchor: 'aws-clf',
                        name: 'AWS Certified Cloud Practitioner (CLF)',
                        organization: 'Amazon Web Services (AWS)',
                        duration: 'September 2021 - September 2027',
                        infoLink:
                            'https://aws.amazon.com/certification/certified-cloud-practitioner/',
                        certificateLink: 'education/aws-cp.pdf',
                        image: 'education/aws-cp.png',
                    },
                ],
            },
            {
                key: '3',
                title: 'Software Testing',
                icon: 'test.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'istqb-ctfl',
                        name: 'ISTQB CTFL - Agile Tester',
                        organization: 'ISTQB',
                        duration: 'August 2016',
                        infoLink: 'https://www.istqb.org/certification-path-root/agile-tester.html',
                        certificateLink: 'education/istqb-agile-tester.pdf',
                        image: 'education/istqb-agile-tester.png',
                    },
                    {
                        key: '2',
                        anchor: 'istqb-ctal',
                        name: 'ISTQB CTAL - Test Manager',
                        organization: 'ISTQB',
                        duration: 'March 2018',
                        infoLink:
                            'https://www.istqb.org/certification-path-root/advanced-level/advanced-level-test-manager.html',
                        certificateLink: 'education/istqb-test-manager.pdf',
                        image: 'education/istqb-test-manager.png',
                    },
                    {
                        key: '3',
                        anchor: 'istqb-mat',
                        name: 'ISTQB MAT - Mobile Application Testing',
                        organization: 'ISTQB',
                        duration: 'February 2020',
                        infoLink: 'https://www.istqb.org/certifications/mobile-tester',
                        certificateLink: 'education/istqb-test-manager.pdf',
                        image: 'education/istqb-mobile-application-tester.png',
                    },
                ],
            },
            {
                key: '4',
                title: 'Product Management',
                icon: 'product-management.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'acsm',
                        name: 'Advanced Certified Scrum Master (A-CSM)',
                        organization: 'Scrum Alliance',
                        duration: 'May 2020 - May 2022',
                        infoLink:
                            'https://www.scrumalliance.org/get-certified/scrum-master-track/advanced-certified-scrummaster',
                        certificateLink: 'education/acsm.pdf',
                        image: 'education/seal-advcsm.png',
                    },
                    {
                        key: '2',
                        anchor: 'cspo',
                        name: 'Certified Scrum Product Owner (CSPO)',
                        organization: 'Scrum Alliance',
                        duration: 'February 2019 - May 2022',
                        infoLink:
                            'https://www.scrumalliance.org/get-certified/product-owner-track/certified-scrum-product-owner',
                        certificateLink: 'education/cspo.pdf',
                        image: 'education/seal-cspo.png',
                    },
                ],
            },
            {
                key: '5',
                title: 'UX Design',
                icon: 'design.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'ixd',
                        name: 'Interaction Design Specialization (Coursera)',
                        organization: 'University of California, San Diego',
                        duration: 'August 2016',
                        infoLink: 'https://www.coursera.org/specializations/interaction-design',
                        certificateLink: 'education/coursera-interaction-design.pdf',
                        image: 'education/coursera-interaction-design.png',
                    },
                ],
            },
            {
                key: '6',
                title: 'Digital Marketing',
                icon: 'marketing.svg',
                certificates: [
                    {
                        key: '1',
                        anchor: 'digital-marketing',
                        name: 'Digital Marketing Specialization (Coursera)',
                        organization: 'University of Illinois at Urbana-Champaign',
                        duration: 'December 2021',
                        infoLink: 'https://www.coursera.org/specializations/digital-marketing',
                        certificateLink: 'education/coursera-digital-marketing.pdf',
                        image: 'education/coursera-digital-marketing.png',
                    },
                ],
            },
        ],
    },

    cv: {
        workExperience: [
            {
                key: '1',
                company: 'Swenson He',
                title: 'Software Engineer (Contractor)',
                duration: 'December 2022 - Present',
            },
            {
                key: '2',
                company: 'Aqarmap',
                title: 'Engineering Director',
                duration: 'May 2021 - February 2022 (1 year, 9 months)',
            },
            {
                key: '3',
                company: 'Areeb Group',
                title: 'Product Manager',
                duration: 'November 2020 - May 2021',
            },
            {
                key: '4',
                company: 'ITWorx',
                title: 'Principal Software Engineer',
                duration: 'November 2017 - October 2020 (3 years)',
            },
            {
                key: '5',
                company: 'Aqarmap',
                title: 'Scrum Master/Agile Tester - later on as Mobile Development Manager',
                duration: 'August 2016 - October 2017 (1 year, 2 months)',
            },
            {
                key: '6',
                company: 'MedsConnect',
                title: 'Product Designer/Manager',
                duration: 'July 2014 - December 2016 (2 years, 6 months)',
            },
            {
                key: '7',
                company: 'Youxel',
                title: 'Mobile Development/Design Consultant',
                duration: 'October 2014 - July 2015 (9 months)',
            },
            {
                key: '8',
                company: 'Artgine (Pentavalue)',
                title: 'Mobile Development Manager',
                duration: 'April 2014 - August 2014 (4 months)',
            },
            {
                key: '9',
                company: 'Freelancer',
                title: 'Freelance iOS/Android Application Developer',
                duration: 'December 2013 - March 2014 (4 months)',
            },
            {
                key: '10',
                company: 'IBM - Egypt',
                title: 'Application Developer',
                duration: 'December 2012 - November 2013 (1 year)',
            },
            {
                key: '11',
                company: 'asgatech',
                title: 'Senior iOS Developer',
                duration: 'July 2012 - November 2012 (4 months)',
            },
            {
                key: '12',
                company: 'ENPPI',
                title: 'Electrical Engineer',
                duration: 'September 2007 - April 2011 (3 years, 6 months)',
            },
            {
                key: '13',
                company: 'Future University in Egypt',
                title: 'Teaching Assistant',
                duration: 'September 2006 - March 2007 (8 months)',
            },
        ],
        certifications: [
            {
                key: '1',
                name: 'Advanced Certified ScrumMaster (A-CSM)®',
                organization: 'Scrum Alliance',
                duration: 'May 2020 - May 2022',
            },
            {
                key: '2',
                name: 'Certified Scrum Product Owner (CSPO)®',
                organization: 'Scrum Alliance',
                duration: 'February 2019 - February 2021',
            },
            {
                key: '3',
                name: 'Advanced Certified Scrum Developer (A-CSD)®',
                organization: 'Scrum Alliance',
                duration: 'August 2019 - May 2022',
            },
            {
                key: '4',
                name: 'Interaction Design Specialization',
                organization: 'Coursera, University of California, San Diego',
                duration: 'August 2016',
            },
            {
                key: '5',
                name: 'ISTQB - FL Agile Tester',
                organization: 'International Software Testing Qualifications Board (ISTQB)',
                duration: 'August 2016',
            },
            {
                key: '6',
                name: 'ISTQB - FL Mobile Application Tester',
                organization: 'International Software Testing Qualifications Board (ISTQB)',
                duration: 'February 2020',
            },
            {
                key: '7',
                name: 'ISTQB - AL Test Manager',
                organization: 'International Software Testing Qualifications Board (ISTQB)',
                duration: 'March 2018',
            },
            {
                key: '8',
                name: 'Certified Kubernetes Application Developer (CKAD)',
                organization: 'The Linux Foundation',
                duration: 'August 2022 - August 2025',
            },
            {
                key: '9',
                name: 'AWS Certified Developer Associate (DVA-C01)',
                organization: 'Amazon Web Services (AWS)',
                duration: 'December 2021 - December 2022',
            },
        ],
    },

    contacts: [
        {
            key: '1',
            title: '+2-011-57939795',
            icon: 'phone.svg',
            link: 'tel:+201157939795',
        },
        {
            key: '2',
            title: 'development@ashatou.com',
            icon: 'email.svg',
            link: 'mailto:development@ashatou.com',
        },
        {
            key: '3',
            title: 'Telegram (ashatoudev)',
            icon: 'telegram.svg',
            link: 'https://telegram.me/ashatoudev',
        },
        {
            key: '4',
            title: 'LinkedIn',
            icon: 'linkedin.svg',
            link: 'https://linkedin.com/in/ashatou/',
        },
        {
            key: '5',
            title: 'Xing',
            icon: 'xing.svg',
            link: 'https://www.xing.com/profile/Abdalrahman_Shatou/cv',
        },
        {
            key: '6',
            title: 'CodeSignal',
            icon: 'code-signal.svg',
            link: 'https://app.codesignal.com/profile/abdalrahma_s',
        },
        {
            key: '7',
            title: 'Stackoverflow',
            icon: 'stackoverflow.svg',
            link: 'https://stackoverflow.com/users/1149647/abdalrahman-shatou',
        },
    ],
};

export default Data;
