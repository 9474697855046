import styles from './CVContentList.module.css';

import CVContentListItem from './CVContentListItem';

function CVContentList({ items, indentTitle }) {
    return (
        <ul className={styles['cv-content-list']}>
            {items.map((item) => {
                return (
                    <li key={item.key}>
                        <CVContentListItem
                            header={item.header}
                            title={item.title}
                            subtitle={item.subtitle}
                            indentTitle={indentTitle}
                        />
                    </li>
                );
            })}
        </ul>
    );
}

export default CVContentList;
