import styles from './WorkExperienceList.module.css';
import Timeline from '../../common/Timeline';
import WorkExperienceItems from './WorkExperienceItems';

function WorkExperienceList({ workExperiences }) {
    return (
        <div className={styles['work-experience-list']}>
            <span id='timeline' className={styles['work-experience-list-anchor']}></span>

            <Timeline />

            <WorkExperienceItems workExperiences={workExperiences} />
        </div>
    );
}

export default WorkExperienceList;
