import styles from './CVContentListItem.module.css';

function CVContentListItem({ header, title, subtitle, indentTitle }) {
    return (
        <div className={styles['cv-content-list-item']}>
            <h1 className={styles['cv-content-list-item-header']}>{header}</h1>
            <h2 className={styles['cv-content-list-item-title']}>
                {indentTitle && <span>-&nbsp;&nbsp;</span>}
                {title}
            </h2>
            <h3 className={styles['cv-content-list-item-subtitle']}>
                {indentTitle && <span>-&nbsp;&nbsp;</span>}
                {subtitle}
            </h3>
        </div>
    );
}

export default CVContentListItem;
