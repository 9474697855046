import styles from './HomeHeaderPhoto.module.css';

const images = require.context('../../../images', true);

function HomeHeaderPhoto() {
    return (
        <div className={styles['home-header-photo']}>
            <div className={styles['home-header-photo-background']}></div>
            <div className={styles['home-header-photo-image-container']}>
                <img
                    src={images('./home/abdalrahman-shatou-home-header.png')}
                    alt='My main'
                    className={styles['home-header-photo-image']}
                />
            </div>
        </div>
    );
}

export default HomeHeaderPhoto;
