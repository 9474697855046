import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './components/home/Home';
import CV from './components/cv/CV';
import RootLayout from './routes/RootLayout';
import WorkExperience from './components/work-experience/WorkExperience';
import Education from './components/education/Education';
import Testimonials from './components/testimonials/Testimonials';
import ContactMe from './components/contact-me/ContactMe';
import { Error } from 'com-ashatou-ui/dist';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/work-experience', element: <WorkExperience /> },
            { path: '/education', element: <Education /> },
            { path: '/testimonials', element: <Testimonials /> },
            { path: '/cv', element: <CV /> },
            { path: '/contact-me', element: <ContactMe /> },
            { path: '/*', element: <Error /> },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
