import styles from './WorkExperienceCell.module.css';
import WorkExperienceCellContent from './WorkExperienceCellContent';

function WorkExperienceCell({ workExperiences }) {
    return (
        <div className={styles['work-experience-list-cell']}>
            {workExperiences.map((workExperience, index) => {
                return (
                    <WorkExperienceCellContent
                        workExperience={workExperience}
                        index={index}
                        key={workExperience.key}
                    />
                );
            })}
        </div>
    );
}

export default WorkExperienceCell;
