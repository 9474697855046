import { LinkButton } from 'com-ashatou-ui/dist';
import styles from './ContactMe.module.css';
import ContactMeHeader from './header/ContactMeHeader';
import ContactMeList from './list/ContactMeList';

const files = require.context('../../files', true);
const images = require.context('../../images', true);

function ContactMe() {
    return (
        <div className={styles['contact-me']}>
            <ContactMeHeader />
            <ContactMeList />
            <LinkButton
                title='Save Contact'
                subtitle='(VCF 470kB)'
                link={files('./contact-me/abdalrahman-shatou.vcf')}
                image={images(`./contact-me/memoji.jpeg`)}
                roundedImage={true}
                openInNewTab={true}
            />
        </div>
    );
}

export default ContactMe;
