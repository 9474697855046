import styles from './HomeWorkExperienceSample.module.css';
import data from '../../../data/Data';
import WorkExperienceList from '../../work-experience/work-experience-list/list/WorkExperienceList';
import { LinkButton } from 'com-ashatou-ui/dist';

function HomeWorkExperienceSample() {
    let firstTwoWorkExperiences = [data.workExperiences.list[0], data.workExperiences.list[1]];

    return (
        <div className={styles['home-work-experience-sample']}>
            <h1 className={styles['home-work-experience-sample-header']}>My Latest Jobs</h1>
            <div className={styles['home-work-experience-sample-job']}>
                <WorkExperienceList workExperiences={firstTwoWorkExperiences} />
            </div>
            <LinkButton title='View More' link={'./work-experience'} />
        </div>
    );
}

export default HomeWorkExperienceSample;
