import CVSectionHeader from '../cv-section-header/CVSectionHeader';
import styles from './CVCertifications.module.css';

import data from '../../../../data/Data';
import CVContentList from '../../common/CVContentList';

function CVCertifications() {
    let certifications = data.cv.certifications;

    return (
        <div className={styles['cv-certifications']}>
            <CVSectionHeader title='Certifications' />

            <CVContentList
                items={certifications.map((certification) => {
                    return {
                        key: certification.key,
                        header: certification.name,
                        title: certification.organization,
                        subtitle: certification.duration,
                    };
                })}
                indentTitle={true}
            />
        </div>
    );
}

export default CVCertifications;
