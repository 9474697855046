import styles from './HomeHeaderAboutMe.module.css';
import data from '../../../data/Data';
import { generalSvg } from 'com-ashatou-ui/dist';

function HomeHeaderAboutMe() {
    let contacts = data.contacts;

    return (
        <div className={styles['home-header-about-me']}>
            <p className={styles['home-header-about-me-hello']}>Hello, There!</p>
            <p className={styles['home-header-about-me-my-name']}>my name is</p>
            <h1 className={styles['home-header-about-me-abdalrahman']}>Abdalrahman Shatou</h1>
            <p className={styles['home-header-about-me-details']}>
                and I am a software engineer. I have been building software solutions for over 15
                years with wide expertise in software development, design, testing, and management.
            </p>
            <ul className={styles['home-header-contacts-list']}>
                {contacts.map((contact) => {
                    return (
                        <li key={contact.key} className={styles['home-header-contacts-list-item']}>
                            <a
                                href={contact.link}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={styles['home-header-contacts-link']}
                            >
                                <img
                                    src={generalSvg(`./${contact.icon}`)}
                                    alt={contact.icon}
                                    className={styles['home-header-contacts-icon']}
                                />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default HomeHeaderAboutMe;
