import EducationCertificationsHeader from './header/EducationCertificationsHeader';
import EducationCertificationsList from './list/EducationCertificationsList';

function EducationCertifications() {
    return (
        <div>
            <EducationCertificationsHeader />

            <EducationCertificationsList />
        </div>
    );
}

export default EducationCertifications;
