import CVSectionHeader from '../cv-section-header/CVSectionHeader';
import styles from './CVWorkExperience.module.css';

import data from '../../../../data/Data';
import CVContentList from '../../common/CVContentList';

function CVWorkExperience() {
    let workExperiences = data.cv.workExperience;

    return (
        <div className={styles['cv-work-experience']}>
            <CVSectionHeader title='Work Experience' />

            <CVContentList
                items={workExperiences.map((workExperience) => {
                    return {
                        key: workExperience.key,
                        header: workExperience.title,
                        title: workExperience.company,
                        subtitle: workExperience.duration,
                    };
                })}
                indentTitle={false}
            />
        </div>
    );
}

export default CVWorkExperience;
