import {
    ExpandCollapseButton,
    ExpandCollapseButtonStyle,
    ExpandCollapseTransition,
    getDevice,
    Device,
    listenToDeviceChange,
} from 'com-ashatou-ui/dist';
import NavigationContext from '../../context/navigation-context';
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import { useContext } from 'react';
import styles from './FooterSection.module.css';

function FooterSection({ title, items, baseLink, useAbsoluteLink, navItem }) {
    let context = useContext(NavigationContext);

    let [isCollapsed, setIsCollapsed] = useState(true);
    let [isMobile, setIsMobile] = useState(getDevice() === Device.MOBILE);

    function toggleSectionList(event) {
        event.preventDefault();
        setIsCollapsed((collapsed) => {
            return !collapsed;
        });
    }

    listenToDeviceChange((device) => {
        if (device === Device.MOBILE) {
            if (!isMobile) {
                setIsMobile(true);
            }
        } else {
            if (isMobile) {
                setIsMobile(false);
            }
        }
    });

    return (
        <div className={styles['footer-section']}>
            <div className={styles['footer-section-header']} onClick={toggleSectionList}>
                <h1 className={styles['footer-section-header-title']}>{title}</h1>
                <ExpandCollapseButton
                    buttonStyle={ExpandCollapseButtonStyle.PLUS}
                    isCollapsed={!isCollapsed}
                    customClassName={styles['footer-section-header-button']}
                />
            </div>
            <ExpandCollapseTransition isCollapsed={isCollapsed} isDisabled={!isMobile}>
                <ul className={styles['footer-section-list']}>
                    {items.map((item) => {
                        return (
                            <li key={item.key}>
                                {useAbsoluteLink && (
                                    <a
                                        href={item.link}
                                        className={styles['footer-section-list-item']}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        {item.title}
                                    </a>
                                )}
                                {!useAbsoluteLink && (
                                    <Link
                                        to={
                                            (useAbsoluteLink && item.anchor) ||
                                            `${baseLink}#${item.anchor}`
                                        }
                                        className={styles['footer-section-list-item']}
                                        onClick={() => {
                                            context.onNavigationItemClicked(navItem);
                                        }}
                                    >
                                        {item.title}
                                    </Link>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </ExpandCollapseTransition>

            <div className={`${styles['footer-section-divider']}`} />
        </div>
    );
}

export default FooterSection;
