import styles from './NavigationBar.module.css';
import { NavigationLogo, NavigationMenuButton } from 'com-ashatou-ui/dist';
import NavigationTitle from '../navigation-title/NavigationTitle';
import NavigationItems from '../navigation-items/NavigationItems';
import NavigationContext from '../../../context/navigation-context';
import { useContext } from 'react';

function NavigationBar({
    isCollapsed,
    darkLogo,
    lightLogo,
    alt,
    onMenuButtonClicked,
    onNavigationItemClicked,
}) {
    let context = useContext(NavigationContext);

    return (
        <div className={styles['nav-bar']}>
            <NavigationLogo
                darkLogo={darkLogo}
                lightLogo={lightLogo}
                alt={alt}
                onLogoClicked={() => {
                    context.onLogoClicked();
                    onNavigationItemClicked();
                }}
            />

            <NavigationTitle />

            <NavigationItems />

            <NavigationMenuButton open={!isCollapsed} onClick={onMenuButtonClicked} />
        </div>
    );
}

export default NavigationBar;
