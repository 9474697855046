import Circle from './Circle';
import styles from './Timeline.module.css';

function Timeline() {
    return (
        <div className={styles['work-experience-timeline']}>
            <Circle />

            <div className={styles['work-experience-content-line']}></div>

            <Circle />
        </div>
    );
}

export default Timeline;
