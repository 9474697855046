import Navigation from '../components/navigation/Navigation';
import Footer from '../components/footer/Footer';
import { NavigationContextProvider } from '../context/navigation-context';
import lightLogo from '../images/abdalrahman-shatou-50-50-light.jpg';
import darkLogo from '../images/abdalrahman-shatou-50-50-dark.jpg';
import { Outlet } from 'react-router-dom';
import styles from './RootLayout.module.css';

function RootLayout() {
    return (
        <NavigationContextProvider>
            <main className={styles['main']}>
                <Outlet />
            </main>

            <Navigation darkLogo={darkLogo} lightLogo={lightLogo} alt={'My photo'} />

            <footer>
                <Footer />
            </footer>
        </NavigationContextProvider>
    );
}

export default RootLayout;
