import data from '../../../../data/Data';
import EducationCertificationsItem from './section/EducationCertificationsItem';
import EducationCertificationsSection from './section/EducationCertificationsSection';
import styles from './EducationCertificationsList.module.css';

function EducationCertificationsList() {
    let certifications = data.certifications.list;

    return (
        <div className={`${styles['education-certifications-section-list']}`}>
            <span
                id='certifications'
                className={styles['education-certifications-section-list-anchor']}
            ></span>
            {certifications.map((certification) => {
                return (
                    <div key={certification.key}>
                        <EducationCertificationsSection
                            title={certification.title}
                            icon={certification.icon}
                        />
                        <div className={`${styles['education-certification-section-items']}`}>
                            {certification.certificates.map((certificate) => {
                                return (
                                    <EducationCertificationsItem
                                        key={certificate.key}
                                        item={certificate}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default EducationCertificationsList;
