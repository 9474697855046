import styles from './WorkExperienceCellContent.module.css';

const images = require.context('../../../../images', true);

function WorkExperienceCellContent({ workExperience, index }) {
    return (
        <div
            key={`${workExperience.key}+${index}`}
            className={styles['work-experience-list-cell-container']}
        >
            <span
                id={workExperience.anchor}
                className={styles['work-experience-list-cell-container-anchor']}
            ></span>
            <div className={styles['work-experience-list-cell-details']}>
                <div className={styles['work-experience-list-cell-details-main']}>
                    <h1 className={styles['work-experience-list-cell-details-year']}>
                        {workExperience.year}
                    </h1>
                    <h2 className={styles['work-experience-list-cell-details-company']}>
                        {workExperience.company}
                    </h2>
                    <h3 className={styles['work-experience-list-cell-details-position']}>
                        {workExperience.position}
                    </h3>
                    <h3 className={styles['work-experience-list-cell-details-duration']}>
                        {workExperience.duration}
                    </h3>
                </div>
                <div className={styles['work-experience-list-cell-details-secondary']}>
                    <p className={styles['work-experience-list-cell-details-responsibilities']}>
                        {workExperience.responsibilities}
                    </p>
                </div>
            </div>
            <div className={styles['work-experience-list-cell-logo']}>
                <img
                    src={images(`./${workExperience.logo}`)}
                    alt={workExperience.logo}
                    className={`${styles['work-experience-list-cell-logo-img']}`}
                />
            </div>
            <div className={styles['work-experience-list-cell-connector']}></div>
        </div>
    );
}

export default WorkExperienceCellContent;
