import styles from './WorkExperience.module.css';
import WorkExperienceHeader from './work-experience-header/WorkExperienceHeader';
import WorkExperienceList from './work-experience-list/list/WorkExperienceList';
import data from '../../data/Data';

function WorkExperience() {
    return (
        <div className={styles['work-experience']}>
            <WorkExperienceHeader />
            <WorkExperienceList workExperiences={data.workExperiences.list} />
        </div>
    );
}

export default WorkExperience;
