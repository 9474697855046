import styles from './EducationCertificationsHeader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';

function EducationCertificationsHeader() {
    return (
        <div className={`${styles['education-certifications']}`}>
            <div className={`${styles['education-certifications-intro']}`}>
                <h1 className={`${styles['education-certifications-header']}`}>
                    <span>
                        <FontAwesomeIcon
                            icon={faCertificate}
                            className={`${styles['education-certifications-header-icon']}`}
                        />
                    </span>
                    Certifications
                </h1>
                <p className={`${styles['education-certifications-description']}`}>
                    I believe in the certification process as a method of verifying and validating
                    both knowledge and experience in a specific function area.
                </p>
            </div>
        </div>
    );
}

export default EducationCertificationsHeader;
