import styles from './HomeCTA.module.css';
import { LinkButton, SVG } from 'com-ashatou-ui/dist';

const images = require.context('../../../images', true);
const files = require.context('../../../files', true);

function HomeCTA() {
    return (
        <div className={styles['home-cta']}>
            <div className={styles['home-cta-buttons']}>
                <LinkButton
                    title='Download CV'
                    subtitle='(PDF 67kB)'
                    link={files('./cv/cv.pdf')}
                    image={SVG.General.pdf}
                    openInNewTab={true}
                />

                <LinkButton
                    title='Save Contact'
                    subtitle='(VCF 470kB)'
                    link={files('./contact-me/abdalrahman-shatou.vcf')}
                    image={images(`./contact-me/memoji.jpeg`)}
                    roundedImage={true}
                    openInNewTab={true}
                />
            </div>
        </div>
    );
}

export default HomeCTA;
