import TestimonialsHeader from './testimonials-header/TestimonialsHeader';
import TestimonialsList from './testimonials-list/TestimonialsList';
import styles from './Testimonials.module.css';
import data from '../../data/Data';

function Testimonials() {
    return (
        <div className={styles['testimonials']}>
            <TestimonialsHeader />
            <TestimonialsList items={data.testimonials} />
        </div>
    );
}

export default Testimonials;
