import styles from './EducationUniversity.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBolt, faCalendarDays, faFile } from '@fortawesome/free-solid-svg-icons';

const images = require.context('../../../images', true);
const files = require.context('../../../files', true);

function EducationUniversity() {
    return (
        <div className={`${styles['education-university']}`}>
            <figure className={styles['education-university-logo']}>
                <img
                    src={images(`./education/ain-shams-university-logo.png`)}
                    alt={'Logo of Ain-shams University'}
                    className={styles['education-university-logo-img']}
                />
                <figcaption className={styles['education-university-logo-caption']}>
                    <h1 className={styles['education-university-logo-caption-header']}>
                        I am a proud graduate of Ain&nbsp;Shams&nbsp;University, one of the top
                        universities in the Middle&nbsp;East.
                    </h1>
                    <div className={styles['education-university-logo-caption-info']}>
                        <h2 className={styles['education-university-logo-caption-faculty']}>
                            <span>
                                <FontAwesomeIcon
                                    icon={faGraduationCap}
                                    className={`${styles['education-university-logo-caption-info-icon']}`}
                                />
                            </span>
                            Faculty of Engineering
                        </h2>
                        <h3 className={styles['education-university-logo-caption-degree']}>
                            <span>
                                <FontAwesomeIcon
                                    icon={faBolt}
                                    className={`${styles['education-university-logo-caption-info-icon']}`}
                                />
                            </span>
                            B.Sc. Degree of Electrical Engineering
                        </h3>
                        <h4 className={styles['education-university-logo-caption-year']}>
                            <span>
                                <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className={`${styles['education-university-logo-caption-info-icon']}`}
                                />
                            </span>
                            September 2006 (Excellent)
                        </h4>
                        <a
                            href={files('./education/faculty_transcript_en.pdf')}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={styles['education-university-logo-caption-transcript']}
                        >
                            <span>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    className={`${styles['education-university-logo-caption-info-icon']}`}
                                />
                            </span>
                            View Transcript
                        </a>
                    </div>
                </figcaption>
            </figure>
        </div>
    );
}

export default EducationUniversity;
