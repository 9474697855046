import { Link } from 'react-router-dom';
import styles from './NavigationMenu.module.css';
import NavigationContext from '../../../context/navigation-context';
import { useContext } from 'react';
import { ExpandCollapseTransition, ExpandCollapseTransitionStyle } from 'com-ashatou-ui/dist';

function NavigationMenu({ isCollapsed, onNavigationMenuItemClicked }) {
    let context = useContext(NavigationContext);

    function selectNavigationItem(navItem) {
        context.onNavigationItemClicked(navItem);
        onNavigationMenuItemClicked();
    }

    return (
        <ExpandCollapseTransition
            isCollapsed={isCollapsed}
            transitionStyle={ExpandCollapseTransitionStyle.PANEL_TRANSITION}
        >
            <div className={styles['nav-menu']}>
                <ul className={styles['nav-menu-items']}>
                    {context.navItems.slice(1).map((navItem) => {
                        return (
                            <li key={navItem.key}>
                                <Link
                                    to={navItem.path}
                                    className={`
                            ${
                                context.selectedNavigationItem.section === navItem.section &&
                                styles.active
                            } ${styles['nav-menu-item']}
                            `}
                                    onClick={() => {
                                        selectNavigationItem(navItem);
                                    }}
                                >
                                    {navItem.section}{' '}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </ExpandCollapseTransition>
    );
}

export default NavigationMenu;
