import styles from './Education.module.css';
import EducationUniversity from './education-university/EducationUniversity';
import EducationCertifications from './education-certifications/EducationCertifications';

function Education() {
    return (
        <div className={styles['education']}>
            <EducationUniversity />

            <EducationCertifications />
        </div>
    );
}

export default Education;
