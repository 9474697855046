import styles from './HomeTestimonials.module.css';
import TestimonialsList from '../../testimonials/testimonials-list/TestimonialsList';
import data from '../../../data/Data';

function HomeTestimonials() {
    let testimonials = data.testimonials;
    var selectedTestimonialsIndices = [];
    var selectedTestimonials = [];

    while (selectedTestimonialsIndices.length < 3) {
        var randomNumber = Math.floor(Math.random() * data.testimonials.length);
        if (selectedTestimonialsIndices.indexOf(randomNumber) === -1)
            selectedTestimonialsIndices.push(randomNumber);
    }

    for (let index = 0; index < selectedTestimonialsIndices.length; index++) {
        selectedTestimonials.push(testimonials[selectedTestimonialsIndices[index]]);
    }

    return (
        <div className={styles['home-testimonials']}>
            <TestimonialsList items={selectedTestimonials} />
        </div>
    );
}

export default HomeTestimonials;
