import styles from './HomeWorkExperience.module.css';
import HomeWorkExperienceBrief from './HomeWorkExperienceBrief';
import HomeWorkExperienceSample from './HomeWorkExperienceSample';

function HomeWorkExperience() {
    return (
        <div className={styles['home-work-experience']}>
            <HomeWorkExperienceBrief />
            <HomeWorkExperienceSample />
        </div>
    );
}

export default HomeWorkExperience;
