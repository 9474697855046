import styles from './WorkExperienceItems.module.css';
import WorkExperienceCell from '../item/WorkExperienceCell';

function WorkExperienceItems({ workExperiences }) {
    let groupedWorkExperiences = [];

    for (let i = 0; i < workExperiences.length; i += 2) {
        groupedWorkExperiences.push(workExperiences.slice(i, i + 2));
    }

    return (
        <div className={styles['work-experience-items']}>
            {groupedWorkExperiences.map((experiencesSet, index) => {
                return (
                    <WorkExperienceCell
                        key={`${experiencesSet[0].key}+${index}`}
                        workExperiences={experiencesSet}
                    />
                );
            })}
        </div>
    );
}

export default WorkExperienceItems;
