import styles from './HomeWorkExperienceBrief.module.css';
import data from '../../../data/Data';
import { generalSvg } from 'com-ashatou-ui/dist';

function HomeWorkExperienceBrief() {
    let workExperienceBriefs = data.workExperiences.brief;

    return (
        <ul className={styles['home-work-experience-brief']}>
            {workExperienceBriefs.map((brief) => {
                return (
                    <li key={brief.key} className={styles['home-work-experience-brief-details']}>
                        <div className={styles['home-work-experience-brief-details-icon']}>
                            <img src={generalSvg(`./${brief.icon}`)} alt={brief.alt} />
                        </div>
                        <div className={styles['home-work-experience-brief-details-info']}>
                            <div
                                className={styles['home-work-experience-brief-details-info-number']}
                            >
                                {brief.number}
                            </div>
                            <div className={styles['home-work-experience-brief-details-info-unit']}>
                                {brief.unit}
                            </div>
                            <div
                                className={
                                    styles['home-work-experience-brief-details-info-experience']
                                }
                            >
                                {brief.experience}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default HomeWorkExperienceBrief;
