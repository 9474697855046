import styles from './EducationCertificationsSection.module.css';

const svg = require.context('../../../../../../node_modules/com-ashatou-ui/dist/svg/general', true);

function EducationCertificationsSection({ title, icon }) {
    return (
        <div className={`${styles['education-certification-section-header']}`}>
            <div className={`${styles['education-certification-section-divider']}`} />

            <h1 className={`${styles['education-certification-section-title']}`}>
                <span>
                    <img
                        src={svg(`./${icon}`)}
                        alt={`${icon}`}
                        className={`${styles['education-certification-section-icon']}`}
                    />
                </span>
                {title}
            </h1>

            <div className={`${styles['education-certification-section-divider']}`} />
        </div>
    );
}

export default EducationCertificationsSection;
