import { useState } from 'react';
import NavigationMenu from './navigation-menu/NavigationMenu';
import NavigationBar from './navigation-bar/NavigationBar';
import styles from './Navigation.module.css';

function Navigation({ darkLogo, lightLogo, alt }) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    function onMenuButtonClicked(closed) {
        setIsCollapsed(closed);
    }

    function onNavigationMenuItemClicked() {
        setIsCollapsed(true);
    }

    return (
        <div className={styles['nav']}>
            <NavigationBar
                isCollapsed={isCollapsed}
                darkLogo={darkLogo}
                lightLogo={lightLogo}
                alt={alt}
                onMenuButtonClicked={onMenuButtonClicked}
                onNavigationItemClicked={onNavigationMenuItemClicked}
            />

            <NavigationMenu
                isCollapsed={isCollapsed}
                onNavigationMenuItemClicked={onNavigationMenuItemClicked}
            />
        </div>
    );
}

export default Navigation;
