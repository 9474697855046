import NavigationContext from '../../context/navigation-context';
import { useContext } from 'react';
import styles from './Footer.module.css';
import data from '../../data/Data';
import FooterSection from './FooterSection';

const files = require.context('../../files', true);

function Footer() {
    let context = useContext(NavigationContext);
    let workExperiences = data.workExperiences.list;
    let certifications = data.certifications.list;
    let certificates = certifications.flatMap((certification) => {
        return certification.certificates;
    });
    let testimonials = data.testimonials;

    let contacts = data.contacts;

    return (
        <div className={`${styles['footer']}`}>
            <div className={`${styles['footer-divider']}`} />
            <div className={`${styles['footer-sections']}`}>
                <FooterSection
                    title={context.navSections.WORK_EXPERIENCE}
                    items={workExperiences.map((experience) => {
                        return { ...experience, title: experience.company };
                    })}
                    baseLink={`/${
                        context.navItemForSection(context.navSections.WORK_EXPERIENCE).path
                    }`}
                    navItem={context.navItemForSection(context.navSections.WORK_EXPERIENCE)}
                />
                <FooterSection
                    title='Certifications'
                    items={certificates.map((certificate, index) => {
                        return {
                            ...certificate,
                            title: certificate.name,
                            key: `${certificate.key}+${index}`,
                        };
                    })}
                    baseLink={`/${context.navItemForSection(context.navSections.EDUCATION).path}`}
                    navItem={context.navItemForSection(context.navSections.EDUCATION)}
                />
                <div>
                    <FooterSection
                        title={context.navSections.TESTIMONIALS}
                        items={testimonials.map((testimonial) => {
                            return {
                                ...testimonial,
                                title: `By ${testimonial.name}`,
                            };
                        })}
                        baseLink={`/${
                            context.navItemForSection(context.navSections.TESTIMONIALS).path
                        }`}
                        useAbsoluteLink={false}
                        navItem={context.navItemForSection(context.navSections.TESTIMONIALS)}
                    />
                    <FooterSection
                        title={context.navSections.CV}
                        items={[
                            {
                                key: 'download-sv',
                                title: 'Download CV',
                                link: files('./cv/cv.pdf'),
                            },
                        ]}
                        baseLink={`/${context.navItemForSection(context.navSections.CV).path}`}
                        useAbsoluteLink={true}
                        navItem={context.navItemForSection(context.navSections.CV)}
                    />
                </div>
                <FooterSection
                    title={context.navSections.CONTACT_ME}
                    items={[
                        ...contacts,
                        {
                            title: 'Save Contact',
                            key: 'save-contact',
                            link: files('./contact-me/abdalrahman-shatou.vcf'),
                        },
                    ]}
                    baseLink={`/${context.navItemForSection(context.navSections.CONTACT_ME).path}`}
                    useAbsoluteLink={true}
                    navItem={context.navItemForSection(context.navSections.CONTACT_ME)}
                />
            </div>

            <div className={`${styles['footer-divider']} ${styles['footer-divider__tech']}`} />
            <h6 className={`${styles['footer-footnote']}`}>
                Technologies used in building this website: React (for frontend development), AWS
                CodeArtifact (for package management), Node.js (for backend development), Amazon
                DocumentDB (database), Amazon S3 (for website hosting), Amazon EKS on EC2 (for the
                web service), Amazon Route 53 (DNS), and Amazon CloudFront (CDN).
            </h6>

            <div className={`${styles['footer-divider']}`} />
            <h6 className={`${styles['footer-footnote']}`}>
                © {new Date().getFullYear()} Abdalrahman Shatou | Build:&nbsp;
                {process.env.REACT_APP_VERSION}
            </h6>
        </div>
    );
}

export default Footer;
