import { Link } from 'react-router-dom';
import styles from './NavigationItems.module.css';
import NavigationContext from '../../../context/navigation-context';
import { useContext } from 'react';

function NavigationItems() {
    let context = useContext(NavigationContext);

    return (
        <ul className={styles['nav-bar-items']}>
            {context.navItems.slice(1).map((navItem) => {
                return (
                    <li key={navItem.key} onClick={() => context.onNavigationItemClicked(navItem)}>
                        <Link
                            to={navItem.path}
                            className={`
                            ${
                                context.selectedNavigationItem.section === navItem.section &&
                                styles.active
                            } ${styles['nav-bar-item']}
                            `}
                        >
                            {navItem.section}{' '}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}

export default NavigationItems;
