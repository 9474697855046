import styles from './ContactMeList.module.css';
import data from '../../../data/Data';
import { generalSvg } from 'com-ashatou-ui/dist';

function ContactMeList() {
    let contacts = data.contacts;

    return (
        <div className={styles['contact-me-list-container']}>
            <ul className={styles['contact-me-list']}>
                {contacts.map((contact) => {
                    return (
                        <li key={contact.key} className={styles['contact-me-list-item']}>
                            <div className={`${styles['contact-me-list-item-icon']}`}>
                                <img src={generalSvg(`./${contact.icon}`)} alt={contact.icon} />
                            </div>
                            <a
                                href={contact.link}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${styles['contact-me-list-item-title']}`}
                            >
                                {contact.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default ContactMeList;
