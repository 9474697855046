import styles from './HomeCertification.module.css';
import EducationCertificationsItem from '../../education/education-certifications/list/section/EducationCertificationsItem';
import data from '../../../data/Data';
import { LinkButton } from 'com-ashatou-ui/dist';

function HomeCertification() {
    let lastCertificate = data.certifications.latest;

    return (
        <div className={styles['home-certification']}>
            <h1 className={styles['home-certification-header']}>My Latest Certification</h1>
            <div className={styles['home-certification-certificate']}>
                <EducationCertificationsItem item={lastCertificate} />
            </div>
            <LinkButton title='View More' link={'./education'} />
        </div>
    );
}

export default HomeCertification;
