import styles from './NavigationTitle.module.css';
import NavigationContext from '../../../context/navigation-context';
import { useContext } from 'react';

function NavigationTitle() {
    let context = useContext(NavigationContext);

    return <div className={styles['nav-bar-title']}>{context.selectedNavigationItem.section}</div>;
}

export default NavigationTitle;
