import HomeCertification from './home-certification/HomeCertification';
import HomeContacts from './home-contacts/HomeContacts';
import HomeCTA from './home-cta/HomeCTA';
import HomeHeader from './home-header/HomeHeader';
import HomeTestimonials from './home-testimonials/HomeTestimonials';
import HomeWorkExperience from './home-work-experience/HomeWorkExperience';

function Home() {
    return (
        <>
            <HomeHeader />
            <HomeWorkExperience />
            <HomeCertification />
            <HomeTestimonials />
            <HomeContacts />
            <HomeCTA />
        </>
    );
}

export default Home;
