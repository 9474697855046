import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constants from '../Constants';

const NavigationContext = React.createContext({
    selectedNavigationItem: null,
    navItems: [],
    navSections: [],
    onNavigationItemClicked: () => {},
    onLogoClicked: () => {},
    navItemForSection: () => {},
});

export const NavigationContextProvider = (props) => {
    const navSections = {
        HOME: '',
        WORK_EXPERIENCE: 'Work Experience',
        EDUCATION: 'Education',
        TESTIMONIALS: 'Testimonials',
        CV: 'CV',
        CONTACT_ME: 'Contact Me',
    };

    const navItems = [
        { key: '0', section: navSections.HOME, path: '' },
        { key: '1', section: navSections.WORK_EXPERIENCE, path: 'work-experience' },
        { key: '2', section: navSections.EDUCATION, path: 'education' },
        { key: '3', section: navSections.TESTIMONIALS, path: 'testimonials' },
        { key: '4', section: navSections.CV, path: 'cv' },
        { key: '5', section: navSections.CONTACT_ME, path: 'contact-me' },
    ];

    const currentPath = useLocation().pathname.substring(1);

    let initialNavigationItem =
        navItems.find((navItem) => navItem.path === currentPath) || navItems[0];

    const [currentNavigationItem, setCurrentNavigationItem] = useState(initialNavigationItem);

    function onLogoClicked() {
        setCurrentNavigationItem(navItems[0]);
        window.scrollTo(0, 0);
        localStorage.setItem(Constants.lastSelectedNavigationItem, JSON.stringify(navItems[0]));
    }

    function onNavigationItemClicked(navItem) {
        setCurrentNavigationItem(navItem);
        window.scrollTo(0, 0);
        localStorage.setItem(Constants.lastSelectedNavigationItem, JSON.stringify(navItem));
    }

    function navItemForSection(navSection) {
        return navItems.find((navItem) => navItem.section === navSection);
    }

    return (
        <NavigationContext.Provider
            value={{
                selectedNavigationItem: currentNavigationItem,
                navItems: navItems,
                navSections: navSections,
                onNavigationItemClicked: onNavigationItemClicked,
                onLogoClicked: onLogoClicked,
                navItemForSection: navItemForSection,
            }}
        >
            {props.children}
        </NavigationContext.Provider>
    );
};

export default NavigationContext;
