import styles from './HomeHeader.module.css';
import HomeHeaderPhoto from './HomeHeaderPhoto';
import HomeHeaderAboutMe from './HomeHeaderAboutMe';

function HomeHeader() {
    return (
        <div className={styles['home-header']}>
            <div className={styles['home-header-content']}>
                <HomeHeaderPhoto />
                <HomeHeaderAboutMe />
            </div>
        </div>
    );
}

export default HomeHeader;
