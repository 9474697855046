import styles from './TestimonialsHeader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

function TestimonialsHeader() {
    return (
        <div className={styles['testimonials-header']}>
            <FontAwesomeIcon
                icon={faQuoteLeft}
                className={`${styles['testimonials-header-icon']} ${styles['testimonials-header-icon__start']}`}
            />

            <h1 className={styles['testimonials-header-text']}>
                I am blessed with having a career working along exceptional professionals and with
                whom I had worked so hard to deliver value to our customers and users. These are
                their testimonials that I am truly grateful for.
            </h1>

            <FontAwesomeIcon
                icon={faQuoteRight}
                className={`${styles['testimonials-header-icon']} ${styles['testimonials-header-icon__end']}`}
            />
        </div>
    );
}

export default TestimonialsHeader;
