import CVCertifications from './cv-sections/cv-certifications/CVCertifications';
import CVEducation from './cv-sections/cv-education/CVEducation';
import CVPersonal from './cv-sections/cv-personal/CVPersonal';
import CVWorkExperience from './cv-sections/cv-work-experience/CVWorkExperience';
import { CVHeader, LinkButton, SVG } from 'com-ashatou-ui/dist';
import styles from './CV.module.css';

const files = require.context('../../files', true);

function CV() {
    return (
        <div className={styles['cv']}>
            <div className={styles['cv-content']}>
                <CVHeader
                    name='Abdalrahman Shatou'
                    email='development@ashatou.com'
                    address='Nasr City, Cairo, Egypt'
                    phoneNumber='+2 011-57939795'
                    title='Software Engineer'
                    website='https://ashatou.com'
                />

                <CVWorkExperience />

                <CVCertifications />

                <CVEducation />

                <CVPersonal />
            </div>

            <LinkButton
                title='Download CV'
                subtitle='(PDF 67kB)'
                link={files('./cv/cv.pdf')}
                image={SVG.General.pdf}
                openInNewTab={true}
            />
        </div>
    );
}

export default CV;
