import CVSectionHeader from '../cv-section-header/CVSectionHeader';
import styles from './CVEducation.module.css';

function CVEducation() {
    return (
        <div className={styles['cv-education']}>
            <CVSectionHeader title='Education' />

            <div className={styles['cv-education-degree']}>
                <h1 className={styles['cv-education-degree-title']}>Bachelor Degree (B.Sc.)</h1>
                <p className={styles['cv-education-degree-details']}>
                    -&nbsp;&nbsp;&nbsp;Ain-Shams University, Electrical Engineering, 2006, with
                    grade of Excellent with Honor.
                </p>
            </div>
        </div>
    );
}

export default CVEducation;
