import styles from './ContactMeHeader.module.css';

function ContactMeHeader() {
    return (
        <h1 className={styles['contact-me-header']}>
            I would love to hear from&nbsp;you 🙂
            <br />
            <span className={styles['contact-me-header-subtitle']}>
                Feel free to contact me at any time.
            </span>
        </h1>
    );
}

export default ContactMeHeader;
