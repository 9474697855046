import styles from './TestimonialsList.module.css';

const images = require.context('../../../images', true);
const files = require.context('../../../files', true);

function TestimonialsList({ items }) {
    return (
        <div className={styles['testimonials-list']}>
            {items.map((testimonialItem) => {
                return (
                    <div className={styles['testimonials-item']} key={testimonialItem.key}>
                        <span
                            id={testimonialItem.anchor}
                            className={styles['testimonials-item-anchor']}
                        ></span>
                        <figure className={styles['testimonials-item-content']}>
                            <img
                                src={images(`./${testimonialItem.photo}`)}
                                alt={testimonialItem.alt}
                                className={styles['testimonials-item-img']}
                            />
                            <div>
                                <a
                                    href={files(`./${testimonialItem.letter}`)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <blockquote className={styles['testimonials-item-quote']}>
                                        {<>&ldquo;{testimonialItem.quote}&rdquo;</>}
                                    </blockquote>
                                </a>
                                <figcaption className={styles['testimonials-item-caption']}>
                                    <span>— </span>
                                    <a
                                        href={testimonialItem.referenceLink}
                                        target='_blank'
                                        className={styles['testimonials-item-name']}
                                        rel='noopener noreferrer'
                                    >
                                        {testimonialItem.name},
                                    </a>
                                    <div className={styles['testimonials-item-position']}>
                                        {testimonialItem.position},
                                    </div>
                                    <div className={styles['testimonials-item-company']}>
                                        {testimonialItem.company}
                                    </div>
                                </figcaption>
                            </div>
                        </figure>
                    </div>
                );
            })}
        </div>
    );
}

export default TestimonialsList;
