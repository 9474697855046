import styles from './WorkExperienceHeader.module.css';
import { SVG } from 'com-ashatou-ui/dist';

function WorkExperienceHeader() {
    return (
        <div className={styles['work-experience-header']}>
            <img
                src={SVG.General.engineer}
                alt='Engineer icon'
                className={`${styles['work-experience-header-icon']}`}
            />
            <h1 className={styles['work-experience-title']}>
                I have over{' '}
                <span className={styles['work-experience-title__emphasis']}>17 years</span> in
                engineering experience. Delivered value in more than 10 organizations, I have worked
                as a software developer, designer, tester, and product manager. I shine in
                cross-platform teams where the focus in on delivering value and utilizing every bit
                of my experience, knowledge, and skills.
            </h1>
        </div>
    );
}

export default WorkExperienceHeader;
